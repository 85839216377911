import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';

const mapStateToProps = ({ ui }) => {
  return { ui };
};

const windowGlobal = typeof window !== 'undefined' && window;

const Redirect = ({ ui }) => {
  if (!ui.formSending && windowGlobal) {
    navigate('/apply');
  }
  return (
    <div className="text-center mt-5" style={{maxWidth:'600px', marginLeft:'auto', marginRight:'auto'}}>
      <p>
        Your application has been succesfully submitted and we are now searching for your loan, 
        this can take up to 2 minutes. Please do not close this window or press the back button 
        on your browser.
      </p>
      <FontAwesomeIcon icon={faSpinner} size="3x" spin />
    </div>
  );
};

export default connect(mapStateToProps)(Redirect);
